

.aboutheader {
  background: url("./img/headerimg2.png") center;
  background-size: cover;
  height: 65vh;
  filter: brightness(0.5);
}
.aboutletter {
  color: rgb(15, 243, 53);
  font-family: beyno;
}

#detailheader {
  text-align: center;
  margin: auto;
  font-size: larger;
}

.aboutimg {
  width: 100%;
}
.video{
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 767px) {
  .abouttext {
    font-size: x-large;
    padding: 5em;
  }
}
