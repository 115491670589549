.jumbotron{
  background-color: #f2ebff;
}
#headertext {
  position: absolute;
  top: 38%;
  width: 100%;
  z-index: 100;
  filter: brightness(1.5);
  padding: 1em;
}

#header1 {
  color: white;
  font-size: 7em;
}
#header2 {
  color: white;
  font-size: 2em;
  margin-top: 1em;
}

#p {
  color: rgb(15, 243, 53);
  font-family: beyno;
}
#n {
  color: rgb(6, 148, 196);
  font-family: beyno;
}
#k {
  color: rgb(243, 66, 125);
  font-family: beyno;
}

.headercarousel img {
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.4);
  z-index: -1;
}

.social-icon:hover {
  transform: scale(
    1.2
  ); /* Adjust the scale factor to control the level of enlargement */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}


@font-face {
  src: url("./BEYNO.otf"), url("./BEYNO.ttf");
  font-family: beyno;
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 767px) {
  #headertext {
    position: absolute;
    top: 30%;
    width: 100%;
  }
  #header1 {
    font-size: 5em;
  }
}
