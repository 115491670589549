.contactcontainer {
  background-image: linear-gradient(45deg, #4998da, #1cb4a0);
  height: 100vh;
}

.contactform {
  position: absolute;
  border: 5px solid rgb(0, 217, 255);
  padding: 3em;
  width: 40%;
  background-image: linear-gradient(135deg, #50aaf3, #20c9b2);
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0px auto;
  left: 0;
  right: 0;
}

.info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 103, 206, 0.6);
  padding: 20px 0 30px 0;
}
.info-box .i {
  font-size: 32px;
  color: #1622cc;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #000000;
  width:1.5em;
  height: 1.5em;
}
.info-box .social-icon {
  font-size: 32px;
  color: #1622cc;
  padding: 8px;
  width:1.5em;
  height: 1.5em;
}
.info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .contactform {
    width: 95%;
  }
}
