
.keysize{
    background-image: linear-gradient(#a2f7cc, #b6baff);
}
.freemium {
  border-image: linear-gradient(#7df8b4, #28c76f) 30;
  border-width: 4px;
  border-style: solid;
  border-radius: 1em;
}
.freemiumtitle{
    background: linear-gradient(#0f9721, #28c76f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.premium {
  border-image: linear-gradient(#abdcff, #0396ff) 30;
  border-width: 4px;
  border-style: solid;
  border-radius: 1em;
}

.premiumtitle{
    background: linear-gradient(#4da1dd, #0396ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pricing-disclaimer{
  border: 5px solid rgb(236, 29, 29);
  color: white;
  background-image: linear-gradient(135deg, #7c2727, #d10808);
  margin: 0px auto 0px;
}

/***************************************
************** ICONS *******************
****************************************/
.pricinglink {
  color: #000000;
}

.priceicon {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
}
.icon-box {
  text-align: center;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  
}
.freemium-box{
  background-color: rgb(177, 177, 177);
}
.premium-box{
  background-color: rgb(32, 107, 95);
}
.membrane-box{
  background-color: rgb(206, 184, 86);
}
.build-box{
  background-color: rgb(135, 82, 146);
}
.icon-box .homeicon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #ffffff;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.icon-box .homeicon i {
  color: #fff;
  font-size: 28px;
}
.icon-box .homeicon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #444444;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}
.icon-box h4 a {
  color: #191919;
}
.icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.pricinglink:hover {
  color: #2e2a2a;
}
.icon-box:hover {
  background: #444444;
  border-color: #444444;
  padding: 70px 20px 90px 20px;
}
.icon-box:hover .homeicon {
  background: #0bf150;
}
.icon-box:hover .homeicon i {
  color: #cc1616;
}
.icon-box:hover .homeicon::before {
  background: #e9e7e7;
}
.icon-box:hover h4,
.icon-box:hover p {
  color: #fff;
}