.navbar{
    position: absolute;
    width: 100%;
    z-index: 999;
    background-color:#6c3ee3 !important;
}
#navlogo{
    font-family:beyno;
}


#basic-navbar-nav .nav-link{
    color: #ffffff; /* Set your desired text color */
}
#basic-navbar-nav .nav-link:hover{
    color: #000000; /* Set your desired text color */
}